import { localize } from 'vee-validate'

const mutations = {
  setLocale (state, locale) {
    state.locale = locale
    this.$i18n.locale = locale
    this.$i18n.setLocaleCookie(locale)
    localize(locale)
  },
  setPartner (state, data) {
    state.partner = data
  },
  setBasicUserInfo (state, data) {
    if (!data) {
      state.userInfo = null
      state.merchantInfo = null
    } else {
      state.userInfo = data.info
      state.merchantInfo = data.merchant
    }
  },
  setLoggedInStatus (state, data) {
    state.isLoggedIn = data
  },
  setOrderInProgressStatus (state, data) {
    state.orderInProgress = data
  },
  setCurrentOrderProgressStatus (state, data) {
    state.currentOrderProgressStatus = data
  },
  showLoading (state) {
    state.loading = true
    window.$nuxt.$f7.preloader.show()
  },
  closeLoading (state) {
    state.loading = false
    window.$nuxt.$f7.preloader.hide()
  },
  updateRunnersIntervalHandler (state, data) {
    clearInterval(state.handelRunnerLocationUpdate)
    state.handelRunnerLocationUpdate = data
  },
  clearRunnersIntervalHandler (state) {
    clearInterval(state.handelRunnerLocationUpdate)
  }
}

export default mutations
