export default (ctx, inject) => {
  let bridge
  if (typeof window.bridge === 'object') {
    bridge = window.bridge
  } else {
    bridge = {
      getName: () => 'WEBBRIDGE',
      getCurrentLocation () {
        const emitCurrentLocation = window.emitCurrentLocation
        const currentLocation = {
          lat: 53.3191,
          lng: -6.2913
        }
        setTimeout(() => {
          emitCurrentLocation(JSON.stringify(currentLocation))
        }, 2000)
      },
      closeApplication: () => {},
      openDialer: (number) => {},
      startLocationSharing: () => {},
      stopLocationSharing: () => {},
      getFCMToken: () => {
        return '22dddd'
      },
      getBuildVersion: () => {
        return 'dev'
      },
      getUUID: () => {
        return '359268072093601'
      },
      startNavigation: (string) => {},
      getLocalStorage: (key) => {}
    }
  }
  inject('bridge', bridge)
}
