const actions = {
  nuxtServerInit ({ commit }, { req }) {
    if (req.session && req.session.authorization) {
      commit('setLoggedInStatus', true)
    } else {
      commit('setLoggedInStatus', false)
    }
    commit('setBasicUserInfo', req.session.user || null)
    commit('setLocale', req.cookies.lang || 'en')
  },
  initData (vuexContext) {
    vuexContext.commit('setLocale', this.$bridge.getLocalStorage('locale') || 'en')
    vuexContext.commit('setPartner', {
      key: 'partner',
      value: vuexContext.state.partner,
      save: true
    })
  },
  login (ctx, data) {
    return this.$axios.$post('/api/auth/login', data)
  },
  loginSuccess (ctx, data) {
    return this.$axios.$post('/api/runner/login-success', data)
  },
  logout ({ commit }) {
    commit('setLoggedInStatus', false)
    return this.$axios.$post('/api/auth/logout').then(() => {
      localStorage.clear()
    })
  },
  master (ctx, { qs } = { qs: {} }) {
    return this.$axios({
      url: '/api/master',
      params: qs
    })
  },
  /* Orders */
  toggleUserStatus ({ state }) {
    state.onDuty = !state.onDuty
  },
  getOrderList (ctx, { qs }) {
    return this.$axios({
      url: '/api/order/list',
      params: qs
    })
  },
  // eslint-disable-next-line
  changeOrderStatus (ctx, { id, status, runner, message, cancelled_username, payment_method, cancelled_by } = {}) {
    let body = {
      order_id: id,
      status,
      message,
      payment_method,
      event_datetime: this.$moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      cancelled_username,
      cancelled_by
    }
    if (runner) {
      body = { ...body, runner }
    }
    return this.$axios.$post('/api/order/status', body)
  },
  updateDeliveryLocation (ctx, body) {
    return this.$axios.$patch('/api/order/location/update', body)
  },
  settlementAggregation (ctx, { qs } = { qs: {} }) {
    return this.$axios.get('/api/order/order-delivery-settlements', {
      params: qs
    })
  },
  /* Notification */
  getNotifications (ctx, { qs } = { qs: {} }) {
    return this.$axios({
      url: '/api/order/notifications',
      params: qs
    })
  },
  updateLocation (ctx, body) {
    return this.$axios.$patch('/api/runner/location/update', body)
  },
  getPaymentMethod (ctx, body) {
    return this.$axios.$get('/api/order/payment-method')
  }
}

export default actions
