export default ({ app }, inject) => {
  const routes = app.router.options.routes.reduce((routes, route) => {
    routes.push({
      name: route.name,
      path: route.path,
      async (routeTo, routeFrom, resolve) {
        route.component().then(c => resolve({ component: c }))
      }
    })

    return routes
  }, [])
  inject('routes', routes)
}
