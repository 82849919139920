import Vue from 'vue'

import utils from '@/assets/js/utils'

export default () => {
  Vue.mixin({
    methods: {
      ...utils
    }
  })
}
