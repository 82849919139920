import Vue from 'vue'

const _tztoUtc = (value, timezone, { format, returnFormat }) => {
  if (!timezone) {
    timezone = 'UTC'
  }
  value = window.$nuxt.$moment.tz(value, format, timezone).utc()
  if (!returnFormat) {
    return value
  }
  return value.format(returnFormat)
}

const _utctoTz = (value, timezone, { format, returnFormat }) => {
  value = window.$nuxt.$moment.utc(value, format)
  if (timezone) {
    value = value.tz(timezone)
  } else {
    value = value.local()
  }
  if (!returnFormat) {
    return value
  }
  return value.format(returnFormat)
}

Vue.filter('tzToUtc', function (value, timezone, { format = 'YYYY-MM-DD HH:mm:ss', returnFormat = 'YYYY-MM-DD hh:mm a' }) {
  return _tztoUtc(value, timezone, { format, returnFormat })
})

Vue.filter('utcToTz', function (value, timezone, { format = 'YYYY-MM-DD HH:mm:ss', returnFormat = 'YYYY-MM-DD hh:mm a' }) {
  return _utctoTz(value, timezone, { format, returnFormat })
})

Vue.filter('calendarFormat', function (value, {
  format = 'YYYY-MM-DD HH:mm:ss',
  returnFormat = 'YYYY-MM-DD hh:mm a',
  utcToTz = false,
  tzToUtc = false,
  timezone = 'UTC'
}) {
  if (!value || (typeof value === 'string' && value.toLowerCase().includes('no'))) {
    return value
  }
  if (utcToTz) {
    value = _utctoTz(value, timezone, { returnFormat: null, format })
  } else if (tzToUtc) {
    value = _tztoUtc(value, timezone, { returnFormat: null, format })
  }
  return window.$nuxt.$moment(value, format).calendar(null, {
    sameDay: '[Today] [at] hh:mm a',
    nextDay: '[Tomorrow] [at] hh:mm a',
    lastDay: '[Yesterday] [at] hh:mm a',
    nextWeek: returnFormat,
    lastWeek: returnFormat,
    sameElse: returnFormat
  })
})

Vue.filter('capitalize', (value) => {
  if (!value) {
    return ''
  }

  value = value.toString()

  return value.charAt(0).toUpperCase() + value.slice(1)
})
