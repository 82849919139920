const getters = {
  getAuthStatus (state) {
    return state.isLoggedIn
  },
  getUserDutyState (state) {
    return state.onDuty
  },
  getOrderInProgressStatus (state) {
    return state.orderInProgress
  },
  getUserPermittedStoreLocation (state) {
    const ids = state.userInfo.locations.map(x => x.id)
    const temp = {
      location_access: null,
      location_ids: null
    }
    if (state.userInfo.locations.length) {
      temp.location_access = 'partial'
      temp.location_ids = ids
      return temp
    } else {
      temp.location_access = 'all'
      temp.location_ids = ids
      return temp
    }
  },
  getUserInfo (state) {
    return state.userInfo
  }
}

export default getters
