import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8774884 = () => interopDefault(import('../pages/about-me.vue' /* webpackChunkName: "pages/about-me" */))
const _7d8f1602 = () => interopDefault(import('../pages/cod.vue' /* webpackChunkName: "pages/cod" */))
const _e41e58c4 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _e14d221a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ae24aa1c = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _69eae20e = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _08062dda = () => interopDefault(import('../pages/setting.vue' /* webpackChunkName: "pages/setting" */))
const _4d4845f9 = () => interopDefault(import('../pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _c738f45c = () => interopDefault(import('../pages/orders/_id/delivery-status.vue' /* webpackChunkName: "pages/orders/_id/delivery-status" */))
const _749c6848 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-me",
    component: _d8774884,
    name: "about-me"
  }, {
    path: "/cod",
    component: _7d8f1602,
    name: "cod"
  }, {
    path: "/dashboard",
    component: _e41e58c4,
    name: "dashboard"
  }, {
    path: "/login",
    component: _e14d221a,
    name: "login"
  }, {
    path: "/notifications",
    component: _ae24aa1c,
    name: "notifications"
  }, {
    path: "/orders",
    component: _69eae20e,
    name: "orders"
  }, {
    path: "/setting",
    component: _08062dda,
    name: "setting"
  }, {
    path: "/orders/:id",
    component: _4d4845f9,
    name: "orders-id"
  }, {
    path: "/orders/:id/delivery-status",
    component: _c738f45c,
    name: "orders-id-delivery-status"
  }, {
    path: "/",
    component: _749c6848,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
