const _replaceUnderScoreWith = (value, _with = ' ') => {
  return value.split('_').join(_with)
}

const getMsgErrors = (errors) => {
  if (errors && Object.values(errors).length) {
    return Object.values(errors)[0]
  }
  return false
}

export default {
  handleError (error, errorMsg = 'Something went wrong!!') {
    if (window.$nuxt.isOffline) {
      errorMsg = 'Please check your network status.'
    }
    const errors = error.response.data && error.response.data.data ? getMsgErrors(error.response.data.data.errors) : null
    this.$f7.toast.create({
      text: error.response ? ((errors || error.response.data.message || error.response.data.error) || errorMsg) : errorMsg,
      closeTimeout: 3000
    }).open()
  },
  setLocalStorage (name, data) {
    localStorage.setItem(name, JSON.stringify({ [name]: data }))
  },
  getLocalStorage (name) {
    let data = localStorage.getItem(name)
    data = JSON.parse(data)
    if (!data) {
      return null
    }
    return data[name]
  },
  removeLocalStorage (name) {
    localStorage.removeItem(name)
  },
  refresh (cb) {
    this.$f7router.refreshPage()

    if (typeof cb === 'function') {
      cb()
    }
  },
  replaceUnderScoreWith (value, _with) { return _replaceUnderScoreWith(value, _with) },
  nextOrderStatus (currentStatus, returnValue = true, returnName = false) {
    const status = {
      queued: {
        nextStatus: 'runner_accepted',
        name: 'Accept'
      },
      runner_assigned: {
        nextStatus: 'runner_accepted',
        name: 'Accept'
      },
      runner_accepted: {
        nextStatus: 'reached_for_pickup',
        name: 'Reached For Pickup'
      },
      reached_for_pickup: {
        nextStatus: 'pickup_complete',
        name: 'Pickup Completed'
      },
      pickup_complete: {
        nextStatus: 'started_for_delivery',
        name: 'Started For Delivery'
      },
      started_for_delivery: {
        nextStatus: 'reached_for_delivery',
        name: 'Reached For Delivery'
      },
      reached_for_delivery: {
        nextStatus: 'delivered',
        name: 'Delivered'
      },
      delivered: {
        nextStatus: 'completed',
        name: 'Completed'
      },
      completed: {
        nextStatus: 'completed',
        name: 'Completed'
      },
      pending_settlement: {
        nextStatus: 'pending_settlement',
        name: 'Pending Settlement'
      },
      cancelled: {
        nextStatus: 'cancelled',
        name: 'Cancelled'
      },
      runner_cancelled: {
        nextStatus: 'cancelled',
        name: 'Runner Cancelled'
      }
    }
    if (returnValue) {
      return status[currentStatus].nextStatus
    } else if (returnName) {
      return status[currentStatus].name
    } else if (returnName && returnValue) {
      return status[currentStatus]
    }
    return status
  },
  returnLatLngObject (latLng) {
    if (!latLng) {
      return false
    } else if (typeof latLng === 'string') {
      return JSON.parse(latLng)
    } else if (typeof latLng === 'object' && latLng.lat && latLng.lng) {
      return latLng
    }
    return false
  },
  toCurrency (price) {
    let currency = this.$store.$i18n.n(price, { style: 'currency', currency: this.$store.state.userInfo.merchant.currency_code, locale: this.$store.state.locale })

    const formatter = new Intl.NumberFormat(this.$store.state.locale, { style: 'currency', currency: this.$store.state.userInfo.merchant.currency_code }).formatToParts(0)

    const intlCurrency = formatter.find(p => p.type === 'currency')?.value || ''

    if (intlCurrency && this.$store.state.userInfo.merchant.custom_attributes?.currency_symbol && !currency.includes(this.$store.state.userInfo.merchant.custom_attributes.currency_symbol)) {
      currency = currency.replace(intlCurrency, this.$store.state.userInfo.merchant.custom_attributes.currency_symbol)
    }

    return currency
  }
}
